[data-component="CreatePackageModal"] {

  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .create-package-modal {
    width: 600px;
    max-width: 100%;
    background-color: var(--background-secondary);
    border-radius: var(--radius-lg);
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  .create-package-modal-header {
    padding: var(--unit-lg) var(--unit-lg);
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--outline-minimal);
    font-weight: var(--font-weight-medium);
    gap: var(--unit-md);
    svg {
      width: var(--unit-lg);
      height: var(--unit-lg);
      opacity: 0.6;
    }
  }

  .create-package-modal-body {
    padding: var(--unit-lg);
    display: flex;
    flex-direction: column;
    gap: var(--unit-lg);
  }

  .package-row {
    display: flex;
    align-items: center;
    gap: var(--unit-md);
    flex-wrap: wrap;
    justify-content: flex-end;
    .package-title {
      flex-shrink: 0;
      font-size: var(--font-size-md);
      &:not(:first-child) {
        margin-top: var(--unit-lg);
      }
    }
    .grouping {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: var(--unit-md);
      &.grow {
        flex-grow: 1;
      }
    }
    [data-component="Textbox"] {
      width: auto;
      flex-grow: 1;
    }
  }

  .package-card-loading {
    min-height: calc(var(--form-lg) * 1.5);
    background: var(--background-input);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: var(--radius-lg);
    color: var(--color-disabled);
    svg {
      width: var(--unit-xl);
      height: var(--unit-xl);
      opacity: 0.6;
    }
  }

  .package-publish-to {
    color: var(--color-disabled);
  }

  .package-error {
    background-color: var(--red-dark);
    color: var(--white);
    padding: var(--unit-md);
    border-radius: var(--radius-lg);
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    white-space: pre-wrap;
    & > span {
      padding: var(--unit-sm)var(--unit-md);
    }
  }

  .package-actions {
    display: flex;
    justify-content: flex-end;  
  }

}