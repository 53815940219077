[data-component="AgentsPackages"] {

  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 0px var(--unit-xl);

  .packages-primer {
    margin-bottom: var(--unit-md);
  }

  .packages-content {
    max-width: var(--screen-sm);
    padding: var(--unit-lg) 0px;
    width: 100%;
    margin: 0 auto;
  }

  .packages-actions {
    margin: 0px auto;
    display: flex;
    flex-direction: column;
    margin-bottom: var(--unit-md);
    gap: var(--unit-md);
    .packages-actions-row {
      display: flex;
      flex-direction: row;
      gap: var(--unit-md);
    }
  }

  .packages-results {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: var(--unit-md);
  }

  .packages-results-empty {
    color: var(--color-disabled);
    background: var(--background-input);
    padding: var(--unit-xl);
    border-radius: var(--radius-lg);
    text-align: center;
  }

}