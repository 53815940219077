[data-component="CodeEditor"] {

  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  &[data-is-treeview-open="false"] {
    .editor-container .editor-left {
      width: 0px;
      border-right: none;
    }
  }

  .editor-container {
    display: flex;
    flex-grow: 1;
    position: relative;
    border-bottom: 1px solid var(--background-secondary);
    overflow: hidden;

    .editor-left {
      width: 300px;
      position: relative;
      flex-shrink: 0;
      overflow: visible;
      z-index: 1;
      transition: width 0.1s ease-in-out;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      border-right: 1px solid var(--background-secondary);

      .mode-code,
      .mode-database {
        max-height: 1000px;
        overflow: auto;
        flex-grow: 1;
        transition: max-height 0.1s ease-in-out;
        &:not(:first-child) {
          border-top: 1px solid var(--background-secondary);
        }
      }

      &[data-mode="code"] {
        .mode-database {
          max-height: 0px;
          overflow: hidden;
          border-top: none;
        }
      }

      &[data-mode="database"] {
        .mode-code {
          max-height: 0px;
          overflow: hidden;
          border-top: none;
        }
      }

      .editor-left-header {
        display: flex;
        align-items: center;
        height: var(--form-md);
        flex-shrink: 0;
        border-top: 1px solid var(--background-secondary);
        border-bottom: 1px solid transparent;
        gap: calc(var(--unit-md) * 1.5);
        cursor: pointer;
        &:first-child {
          border-top-color: transparent;
        }
        & > span {
          display: flex;
          align-items: center;
        }
        span.header-icon,
        span.header-chevron {
          svg {
            color: var(--color-disabled);
            width: var(--unit-lg);
            height: var(--unit-lg);
          }
        }
        span.header-icon svg {
          margin-left: calc(var(--unit-lg) + var(--unit-sm) + var(--unit));
        }
        span.header-chevron svg {
          margin-right: var(--unit-lg);
        }
        &:hover {
          background: var(--background-secondary);
        }
      }

      .tree-view-container {
        flex-grow: 1;
        position: relative;
        width: 300px;
        height: 100%;
        overflow: auto;
        &.loading {
          overflow: hidden;
        }
      }

      .sqlite-database-list {
        font-size: var(--font-size-sm);
        .list-empty {
          display: flex;
          padding: var(--unit-md);
          color: var(--color-disabled);
          align-items: center;
          justify-content: center;
          min-height: var(--form-md);
        }
        .list-table-container {
          padding: var(--unit-md);
          .list-table {
            border-radius: var(--radius);
            display: flex;
            align-items: center;
            gap: var(--unit-lg);
            padding: var(--unit-md);
            padding-left: calc(var(--unit-lg));
            cursor: pointer;
            color: var(--color-disabled);
            font-size: var(--font-size-sm);
            &:hover {
              background: var(--background-secondary);
              color: var(--color-primary);
            }
            &[data-selected="true"] {
              background: var(--background-tertiary);
              color: var(--color-primary);
              .table-icon {
                color: var(--blue-dark);
                opacity: 1;
              }
            }
            .table-icon, .table-name {
              display: flex;
            }
            .table-icon {
              opacity: 0.5;
              svg {
                width: calc(var(--unit-md) * 1.5);
                height: calc(var(--unit-md) * 1.5);
              }
            }
          }
        }
      }

    }

    .editor-right {
      position: relative;
      display: flex;
      flex-direction: column;
      flex-shrink: 1;
      flex-grow: 1;
      z-index: 2;

      .tree-view-toggle {
        position: absolute;
        top: var(--form-md);
        left: -1px;
        z-index: 100;
        overflow: visible;
        display: flex;
        flex-direction: column;
        justify-content: center;
        color: var(--color-disabled);
        cursor: pointer;
        &:hover {
          color: var(--color-primary);
          .tree-view-toggle-grab:nth-child(2):before {
            background: var(--background-secondary);
          }
        }
        .tree-view-toggle-grab {
          width: calc(var(--unit-sm) * 2);
          height: calc(var(--unit-sm) * 2);
          margin: var(--unit-md) 0px;
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          z-index: 2;
          & > svg {
            width: calc(var(--unit-md) * 1.5);
            height: calc(var(--unit-md) * 1.5);
            z-index: 2;
          }
          z-index: 1;
          width: calc(var(--unit-sm) * 4);
          height: 36px;
          border: 1px solid var(--background-secondary);
          border-left-color: transparent;
          border-top-right-radius: var(--radius-lg);
          border-bottom-right-radius: var(--radius-lg);
          background: var(--background-secondary);
          &:before {
            z-index: 1;
            content: '';
            display: block;
            position: absolute;
            width: 100%;
            height: 100%;
            border-radius: var(--radius-lg);
          }
        }
      }

      .editor-right-code {
        position: relative;
        flex-grow: 1;
        display: flex;
        flex-direction: column;

        .editor-right-code-header {
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          border-bottom: 1px solid var(--background-secondary);
          .file-tabs-container {
            flex-shrink: 1;
            overflow: hidden;
            position: relative;
            margin-bottom: -1px;
            &.loading {
              overflow: hidden;
            }
          }
          [data-component="ContentButton"] {
            margin-top: var(--unit-md);
            margin-right: var(--unit-md);
          }
        }

        .text-editor {
          position: relative;
          flex-shrink: 0;
          flex-grow: 1;
          position: relative;
          &.loading {
            overflow: hidden;
          }
        }

      }

      .editor-right-database {
        position: relative;
        overflow: auto;
      }

    }

  }

  .editor-footer {
    display: flex;
    width: 100%;
    flex-shrink: 0;
    position: relative;
    color: var(--color-secondary);
    font-size: var(--font-size-sm);
    padding-left: var(--unit-lg);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    & > span {
      display: flex;
      align-items: center;
      padding: var(--unit-sm) var(--unit-md);
      &.editor-filename {
        flex-shrink: 1;
        overflow: hidden;
        & > span {
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      &.editor-position {
        justify-content: center;
        width: 48px;
        color: var(--color-disabled);
        &:before {
          display: inline-block;
          content: '( ';
        }
        &:after {
          display: inline-block;
          content: ' )';
        }
      }
      &.editor-language {
        color: var(--color-disabled);
      }
    }
    .editor-footer-actions {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: var(--unit-md);
      margin: var(--unit-md);
    }
  }

}

control[control] {
  color: var(--black);
  a {
    text-decoration: none !important;
  }
}