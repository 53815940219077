[data-component="AgentsKeychain"] {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: var(--unit-lg) var(--unit-xl);

  .keychain-primer,
  .keychain-faq,
  .keychain-list {
    margin: 0px auto;
    max-width: var(--screen-sm);
    width: 100%;
  }

  .keychain-primer {
    margin-bottom: var(--unit-md);
  }

  .keychain-list {
    .key-empty {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      color: var(--color-disabled);
      height: var(--form-md);
    }
    .keychain-rows {
      border-radius: var(--radius-lg);
      background: var(--background-secondary);
      padding: var(--unit-md) 0px;
      overflow: hidden;
    }
    .key-row {
      display: flex;
      align-items: center;
      padding: var(--unit-sm) var(--unit-lg);
      padding-left: calc(var(--unit-md) + var(--unit-sm));
      background: transparent;
      transition: background 0.1s ease-in-out;
      gap: var(--unit-md);
      flex-wrap: wrap;

      textarea[disabled], input[disabled] {
        background: transparent !important;
        color: var(--color-secondary) !important;
      }

      [data-component="ContentButton"][data-disabled="true"] {
        background: transparent !important;
      }

      .key-name {
        display: flex;
        align-items: center;
        flex-shrink: 0;
        min-width: 200px; 
        gap: var(--unit-md);
        .icon {
          margin: 0px var(--unit-md);
          width: calc(var(--unit-lg));
          height: calc(var(--unit-lg));
          color: var(--color-tertiary);
          flex-shrink: 0;
          &.missing {
            color: var(--red-dark);
          }
        }
      }

      .key-value {
        flex-shrink: 0;
        flex-grow: 1;
        min-width: 200px;
      }

      .key-actions {
        flex-grow: 0;
        flex-shrink: 0;
        display: flex;
        gap: var(--unit-sm);
        justify-content: flex-end;
      }
    }

    .add-key-container {
      display: flex;
      justify-content: center;
      padding: var(--unit-xl) 0;
    }
  }

  .keychain-example {
    // border-radius: var(--radius-lg);
    // background: var(--background-secondary);
    margin-top: var(--unit-xl);
  }

}